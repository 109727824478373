<template>
  <form>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">
          {{ displayDate(this.form.date) }}
        </p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field :type="hasError('content')" :message="message('content')">
          <b-radio-button
            v-model="form.content"
            v-for="item in contents"
            :key="'content-' + item.value"
            :native-value="item.value"
            :type="item.type"
            size="is-small"
            expanded
          >
            <span>{{ item.label }}</span>
          </b-radio-button>
        </b-field>
        <fieldset>
          <legend class="is-size-6 mb-2">勤務時間</legend>
          <b-field grouped class="pl-4">
            <b-field
              label="出勤"
              :type="hasError('clockin_time')"
              :message="message('clockin_time')"
            >
              <b-input
                type="time"
                size="is-small"
                icon="clock-start"
                v-model="form.clockin_time"
              />
            </b-field>
            <b-field class="next-check-box">
              <b-checkbox
                type="is-primary"
                size="is-small"
                :value="false"
                disabled
                >翌</b-checkbox
              >
            </b-field>

            <b-field
              label="退勤"
              :type="hasError('clockout_time')"
              :message="message('clockout_time')"
            >
              <b-input
                type="time"
                size="is-small"
                icon="clock-end"
                v-model="form.clockout_time"
              />
            </b-field>
            <b-field class="next-check-box">
              <b-checkbox
                type="is-primary"
                size="is-small"
                v-model="form.is_next_clockout"
                >翌</b-checkbox
              >
            </b-field>
          </b-field>
        </fieldset>

        <b-field label="作業概要" class="ml-4 my-2">
          <b-input
            type="test"
            size="is-small"
            icon="pencil"
            v-model="form.description"
          ></b-input>
        </b-field>

        <fieldset>
          <legend class="is-size-6 mb-2">休憩時間</legend>
          <b-field
            grouped
            v-for="(break_time, index) in form.break_times"
            :key="'bt-' + index"
            class="pl-4"
          >
            <b-field label="開始">
              <b-input
                type="time"
                size="is-small"
                icon="clock-start"
                v-model="break_time.begin_at"
                :disabled="break_time.$delete"
              ></b-input>
            </b-field>

            <b-field class="next-check-box">
              <b-checkbox
                type="is-primary"
                size="is-small"
                v-model="break_time.is_next_begin_at"
                >翌</b-checkbox
              >
            </b-field>

            <b-field label="終了">
              <b-input
                type="time"
                size="is-small"
                icon="clock-end"
                v-model="break_time.end_at"
                :disabled="break_time.$delete"
              ></b-input>
            </b-field>

            <b-field class="next-check-box">
              <b-checkbox
                type="is-primary"
                size="is-small"
                v-model="break_time.is_next_end_at"
                >翌</b-checkbox
              >
            </b-field>

            <b-button
              :type="{
                'is-light': true,
                'is-danger': !break_time.$delete,
                'is-primary': break_time.$delete,
              }"
              :icon-left="break_time.$delete ? 'undo' : 'minus'"
              size="is-small"
              outlined
              @click="removeBreakTime(index)"
            >
            </b-button>
          </b-field>
        </fieldset>

        <b-field class="mt-3">
          <b-button
            size="is-small"
            type="is-primary is-light"
            icon-left="coffee"
            outlined
            @click="addBreakTime"
            >休憩追加
          </b-button>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button label="キャンセル" @click="$emit('close')" expanded />
        <b-button
          label="保存"
          type="is-primary"
          expanded
          @click="save"
          :loading="storing"
        />
      </footer>
    </div>
  </form>
</template>

<script>
import dayjs from "dayjs";
import { CONTENT, CONTENT_ON_HOLIDAY } from "../constants";
import {
hasAnyOverflows,
hasAnyOverlappedBreakTimes,
hasInvalidBreakTimes,
} from "../logic/attendance_sheet";
import storeMixin, { clone } from "../store-mixin";

function initialForm(value) {
  const date = dayjs(value.date);
  return {
    date: date.toDate(),
    content: value.content,
    clockin_time: value.clockin_time
      ? dayjs(value.clockin_time).format("HH:mm")
      : "",
    clockout_time: value.clockout_time
      ? dayjs(value.clockout_time).format("HH:mm")
      : "",
    is_next_clockout: value.clockout_time
      ? date.isBefore(value.clockout_time, "day")
      : false,
    break_times: clone(value.break_times).map((v) => ({
      id: v.id,
      begin_at: v.begin_at ? dayjs(v.begin_at).format("HH:mm") : "",
      end_at: v.end_at ? dayjs(v.end_at).format("HH:mm") : "",
      is_next_begin_at: v.begin_at ? date.isBefore(v.begin_at, "day") : false,
      is_next_end_at: v.end_at ? date.isBefore(v.end_at, "day") : false,
    })),
    description: value.description,
  };
}

function toRequestBody(form) {
  const {
    date,
    clockin_time,
    clockout_time,
    is_next_clockout,
    break_times,
    ...requestBody
  } = clone(form);
  const today = dayjs(date);
  requestBody.clockin_time =
    clockin_time === "" ? null : formatDateTime(today, clockin_time, false);
  requestBody.clockout_time =
    clockout_time === ""
      ? null
      : formatDateTime(today, clockout_time, is_next_clockout);
  requestBody.break_times = break_times.map((v) => ({
    id: v.id,
    begin_at: v.$delete
      ? null
      : formatDateTime(today, v.begin_at, v.is_next_begin_at),
    end_at: v.$delete
      ? null
      : formatDateTime(today, v.end_at, v.is_next_end_at),
  }));

  return requestBody;
}

function validate(requestBody) {
  let message = "";
  if (requestBody.content !== CONTENT.WORK.value) {
    return message;
  }
  if (requestBody.clockin_time >= requestBody.clockout_time) {
    message += "出勤時間と退勤時間が同時または逆転しています<br>";
  }
  if (hasInvalidBreakTimes(requestBody)) {
    message +=
      "休憩時間に開始時間と終了時間が同時または逆転しているところがあります<br>";
  }
  if (hasAnyOverlappedBreakTimes(requestBody)) {
    message += "休憩時間に重なりがあります<br>";
  }
  if (hasAnyOverflows(requestBody)) {
    message += "休憩時間が勤務時間からはみ出しています";
  }

  return message;
}

function formatDateTime(date, time, isNext) {
  const d = (isNext ? date.add(1, "day") : date).format("YYYY-MM-DD");
  return `${d}T${time}:00`;
}

export default {
  name: "AttendanceForm",
  mixins: [storeMixin],
  props: {
    value: Object,
  },
  data() {
    return {
      form: initialForm(this.value),
      isHoliday: this.value.is_holiday,
    };
  },
  watch: {
    value(value) {
      this.form = initialForm(value);
      this.isHoliday = value.is_holiday;
    },
    "form.content": function (value) {
      if (value !== CONTENT.WORK.value) {
        this.form.clockin_time = "";
        this.form.clockout_time = "";
        this.form.is_next_clockout = false;
        this.form.break_times = this.form.break_times.map((v) => ({
          id: v.id,
          begin_at: "",
          end_at: "",
          is_next_begin_at: false,
          is_next_end_at: false,
          $delete: true,
        }));
      }
    },
  },
  computed: {
    contents() {
      return this.isHoliday ? CONTENT_ON_HOLIDAY : CONTENT;
    },
  },
  methods: {
    displayDate(date) {
      return dayjs(date).format("M/D (dd)");
    },
    addBreakTime() {
      this.form.break_times.push({
        id: null,
        begin_at: null,
        end_at: null,
        is_next_begin_at: false,
        is_next_end_at: false,
      });
    },
    removeBreakTime(index) {
      const bt = this.form.break_times[index];
      if (bt.id === null) {
        this.form.break_times.splice(index, 1);
      } else {
        this.$set(bt, "$delete", !bt.$delete);
      }
    },
    async save() {
      try {
        const requestBody = toRequestBody(this.form);

        const message = validate(requestBody);
        if (message.length !== 0) {
          this.$buefy.toast.open({
            duration: 5000,
            message,
            position: "is-top",
            type: "is-danger",
          });
          return;
        }

        await this.store("my/putAttendance", {
          date: dayjs(this.form.date),
          requestBody: requestBody,
        });
        this.$emit("close");
        this.form = initialForm(this.value);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style>
.field.is-grouped .field.next-check-box {
  margin-right: 0.5rem;
  margin-top: -0.5rem;
}

.field.next-check-box .b-checkbox {
  flex-direction: column;
}

.field.next-check-box .b-checkbox .control-label {
  padding: 0;
  order: -1;
}
</style>
