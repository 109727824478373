<template>
  <div class="container is-max-desktop">
    <period-nav-bar
      :prevTo="prevTo"
      :nextTo="nextTo"
      :nextDisabled="nextYearMonth === undefined"
    >
      <b-icon :icon="isClosed ? 'lock' : 'lock-open-variant'"></b-icon>
      <span class="pl-2">{{ targetYearMonth }}</span>
    </period-nav-bar>

    <div v-if="sheet === undefined && !isForAdmin" class="m-4">
      <b-button
        icon-left="pencil"
        type="is-primary"
        expanded
        @click="addSheet"
        :loading="storing"
        >作成</b-button
      >
    </div>
    <div v-else>
      <b-table
        ref="attendances"
        striped
        narrowed
        :mobile-cards="false"
        :data="attendances"
        :selected.sync="selected"
        @select="handleRowSelect"
        class="attendances"
        :loading="loading"
      >
        <b-table-column field="date" label="日" v-slot="props" centered>
          {{ displayDate(props.row.date) }}
        </b-table-column>
        <b-table-column field="dayOfWeek" label="曜日" v-slot="props" centered>
          {{ displayDayOfWeek(props.row.date) }}
        </b-table-column>
        <b-table-column field="content" label="" v-slot="props" centered>
          <b-button
            size="is-small"
            class="content-button"
            :type="contentType(props.row.content, props.row.is_holiday)"
          >
            <span>{{
              displayContent(props.row.content, props.row.is_holiday)
            }}</span>
          </b-button>
        </b-table-column>
        <b-table-column field="clockin_time" label="出" v-slot="props" centered>
          <span>{{ displayTime(props.row.clockin_time) }}</span>
        </b-table-column>
        <b-table-column
          field="clockout_time"
          label="退"
          v-slot="props"
          centered
        >
          <span>{{ displayTime(props.row.clockout_time) }}</span>
        </b-table-column>
        <b-table-column field="hours" label="時間" v-slot="props" centered>
          <span :class="{ 'has-text-danger': isInvalidHours(props.row) }">{{
            displayHourMinute(calcWorkMinutes(props.row))
          }}</span>
        </b-table-column>
      </b-table>

      <table class="table is-striped is-narrow is-fullwidth mt-4">
        <caption>
          <b-icon icon="table-clock"></b-icon>
        </caption>
        <tbody>
          <tr>
            <th>所定日数</th>
            <td class="has-text-right">
              {{ sheet.prescribed_work_days }}
              <span class="is-size-7">日</span>
            </td>
            <th>所定時間</th>
            <td class="has-text-right">
              {{ sheet.prescribed_work_days * 8 }}
              <span class="is-size-7">時間</span>
            </td>
          </tr>
          <tr>
            <th>労働日数</th>
            <td class="has-text-right">
              {{ totalWorkDays }} <span class="is-size-7">日</span>
            </td>
            <th>労働時間</th>
            <td class="has-text-right">
              {{ totalWorkHours.hour }} <span class="is-size-7">時間</span>
              {{ totalWorkHours.minute }} <span class="is-size-7">分</span>
            </td>
          </tr>
          <tr>
            <th>欠勤日数</th>
            <td class="has-text-right">
              {{ totalAbsentDays }} <span class="is-size-7">日</span>
            </td>
            <th>年休日数</th>
            <td class="has-text-right">
              {{ totalPaidLeaves }} <span class="is-size-7">日</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="m-4" v-if="!isClosed && !isForAdmin">
      <b-button
        icon-left="lock"
        type="is-warning"
        expanded
        :loading="storing"
        @click="closeSheet"
        :disabled="!isClosable"
        >締め</b-button
      >
    </div>

    <div class="m-4" v-if="isClosed && isForAdmin">
      <b-button
        icon-left="lock-open-variant"
        type="is-warning"
        expanded
        :loading="storing"
        @click="cancelSheet"
        >締め解除</b-button
      >
    </div>
    <b-modal
      v-model="isFormOpen"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="['escape']"
    >
      <template #default="props">
        <attendance-form
          @close="handleFormClose(props)"
          :value="form"
        ></attendance-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dayjs from "dayjs";
import AttendanceForm from "../components/AttendanceForm.vue";
import PeriodNavBar from "../components/PeriodNavBar";
import { CONTENT, CONTENT_ON_HOLIDAY } from "../constants";
import fetchMixin from "../fetch-mixin";
import {
hasAnyOverflows,
hasAnyOverlappedBreakTimes,
hasTooFewBreakTimes,
hasTooMuchBreakTimes,
} from "../logic/attendance_sheet";
import store from "../store";
import storeMixin, { clone } from "../store-mixin";

function calcElapsedTime(start, end) {
  let elapsed = end.diff(start, "minutes");

  if (Number.isNaN(elapsed)) {
    return 0;
  }

  if (elapsed < 0) {
    // 日跨ぎ対応: ex) 22:00 to 7:00 -> -15h 0m -> 9h 0m
    elapsed += 24 * 60;
  }
  return elapsed;
}

export default {
  name: "AttendanceSheet",
  mixins: [storeMixin, fetchMixin],
  components: { PeriodNavBar, AttendanceForm },
  data() {
    return {
      loading: false,
      selected: null,
      form: {
        date: null,
        content: null,
        clockin_time: null,
        clockout_time: null,
        break_times: [],
        description: null,
      },
      isFormOpen: false,
    };
  },
  props: {
    userId: {
      type: [String, Number],
    },
    year: {
      type: [String, Number],
      default: () => dayjs().get("year"),
    },
    month: {
      type: [String, Number],
      default: () => dayjs().get("month") + 1,
    },
  },
  computed: {
    period() {
      return dayjs({ year: this.year, month: this.month - 1 });
    },
    targetYearMonth() {
      return `${this.year}年${this.month}月`;
    },
    prevYearMonth() {
      const date = this.period.subtract(1, "month");
      return { year: date.get("year"), month: date.get("month") + 1 };
    },
    nextYearMonth() {
      if (this.period.isSame(dayjs(), "month")) {
        return undefined;
      }
      const date = this.period.add(1, "month");
      return { year: date.get("year"), month: date.get("month") + 1 };
    },
    isClosable() {
      const endOfMonth = this.period.endOf("month");
      const isHoliday = this.$store.state.holidays.has(
        dayjs().format("YYYY-MM-DD")
      );
      const today = dayjs();
      return (
        this.sheet !== undefined &&
        (this.sheet.attendances?.length === endOfMonth.date() ||
          (today.isSame(endOfMonth) && isHoliday) ||
          today.isAfter(endOfMonth))
      );
    },
    sheet() {
      const period = this.period.format("YYYYMM");
      if (this.isForAdmin) {
        const userIndex = this.$store.state.admin.users.findIndex(
          (v) => +v.id === +this.userId
        );
        if (userIndex === -1) {
          return undefined;
        }
        const index = this.$store.state.admin.users[
          userIndex
        ].attendance_sheets.findIndex(
          (v) => v.period_year + v.period_month === period
        );
        return this.$store.state.admin.users[userIndex].attendance_sheets[
          index
        ];
      } else {
        return this.$store.state.my.attendance_sheets[period];
      }
    },
    totalWorkHours() {
      const minutes = this.sheet?.attendances
        ?.map((v) => this.calcWorkMinutes(v))
        ?.reduce((a, c) => a + c, 0);

      return { hour: Math.floor(minutes / 60), minute: minutes % 60 };
    },
    totalWorkDays() {
      return this.sheet?.attendances?.filter(
        (v) => v.content === CONTENT.WORK.value
      )?.length;
    },
    totalAbsentDays() {
      return this.sheet?.attendances?.filter(
        (v) => v.content === CONTENT.ABSENCE.value && !v.is_holiday
      )?.length;
    },
    totalPaidLeaves() {
      return this.sheet?.attendances?.filter(
        (v) => v.content === CONTENT.PAID_DAY_OFF.value
      )?.length;
    },
    isClosed() {
      return this.sheet === undefined ? false : this.sheet.closed_at !== null;
    },
    attendances() {
      return this.sheet?.attendances ?? [];
    },
    isForAdmin() {
      return this.userId !== undefined;
    },
    nextTo() {
      return this.isForAdmin
        ? {
            name: "adminAttendanceSheet",
            params: { userId: this.userId, ...this.nextYearMonth },
          }
        : { name: "attendanceSheet", params: this.nextYearMonth };
    },
    prevTo() {
      return this.isForAdmin
        ? {
            name: "adminAttendanceSheet",
            params: { userId: this.userId, ...this.prevYearMonth },
          }
        : { name: "attendanceSheet", params: this.prevYearMonth };
    },
  },
  methods: {
    displayDate(date) {
      return dayjs(date).format("D");
    },
    displayDayOfWeek(date) {
      return dayjs(date).format("dd");
    },
    displayContent(value, isHoliday) {
      const lookup = isHoliday
        ? Object.values(CONTENT_ON_HOLIDAY)
        : Object.values(CONTENT);
      return lookup.find((v) => v.value === value).label;
    },
    displayTime(time) {
      if (time === null) {
        return "";
      } else {
        return dayjs(time).format("HH:mm");
      }
    },
    displayHourMinute(minutes) {
      if (minutes === 0) {
        return "";
      }
      const hour = Math.floor(minutes / 60);
      const min = minutes % 60;
      return (
        hour.toString().padStart(2, "0") + ":" + min.toString().padStart(2, "0")
      );
    },
    contentType(value, isHoliday) {
      const lookup = isHoliday
        ? Object.values(CONTENT_ON_HOLIDAY)
        : Object.values(CONTENT);
      return lookup.find((v) => v.value === value).type;
    },
    calcWorkMinutes(attendance) {
      if (
        attendance.clockin_time === null ||
        attendance.clockout_time === null
      ) {
        return 0;
      }
      const clockIn = dayjs(attendance.clockin_time);
      const clockOut = dayjs(attendance.clockout_time);

      let elapsed = calcElapsedTime(clockIn, clockOut);

      if (
        Array.isArray(attendance.break_times) &&
        attendance.break_times.length > 0
      ) {
        const breakTimeMinutes = attendance.break_times
          .filter((v) => v.begin_at !== null && v.end_at !== null)
          .map((v) => calcElapsedTime(dayjs(v.begin_at), dayjs(v.end_at)))
          .reduce((a, c) => a + c, 0);
        elapsed -= breakTimeMinutes;
      }
      return elapsed;
    },
    handleRowSelect(row) {
      if (this.isClosed || this.isForAdmin) {
        return;
      }
      this.form = {
        date: dayjs(row.date).toDate(),
        content: row.content,
        clockin_time: row.clockin_time,
        clockout_time: row.clockout_time,
        break_times: clone(row.break_times),
        description: row.description,
        is_holiday: row.is_holiday,
      };
      this.isFormOpen = true;
    },
    handleFormClose(props) {
      props.close();
      this.selected = null;
    },
    isInvalidHours(row) {
      return (
        hasAnyOverlappedBreakTimes(row) ||
        hasAnyOverflows(row) ||
        hasTooFewBreakTimes(row) ||
        hasTooMuchBreakTimes(row)
      );
    },
    async fetchData() {
      try {
        this.fetch(async () => {
          if (this.sheet !== undefined) {
            if (this.isForAdmin) {
              await this.$store.dispatch("admin/getAttendanceSheet", {
                userId: this.userId,
                attendanceSheetId: this.sheet.id,
              });
            } else {
              await this.$store.dispatch(
                "my/getAttendanceSheet",
                this.sheet.id
              );
            }
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async addSheet() {
      try {
        await this.store("my/addAttendanceSheet", {
          period_year: this.year,
          period_month: this.month,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async closeSheet() {
      this.$buefy.dialog.confirm({
        title: "注意",
        message: `${this.targetYearMonth}の勤務表を締めます。<br>締め状態を解除したい場合は管理者に連絡してください。`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.store("my/closeAttendanceSheet", this.sheet);
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
    async cancelSheet() {
      this.$buefy.dialog.confirm({
        title: "注意",
        message: `${this.targetYearMonth}の勤務表の締め状態を解除します。`,
        type: "is-warning",
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.store("admin/cancelAttendanceSheetClosing", {
              userId: this.userId,
              attendanceSheetId: this.sheet.id,
            });
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  },
  watch: {
    $route: "fetchData",
  },
  async beforeRouteEnter(route, _redirect, next) {
    store.commit("setLoading", true);
    try {
      if (route.name === "adminAttendanceSheet") {
        const period =
          route.params.year.toString() +
          route.params.month.toString().padStart(2, "0");
        const userId = route.params.userId;
        await store.dispatch("admin/enterAttendanceSheet", { period, userId });
      } else {
        const period =
          route.name === "latestAttendanceSheet"
            ? dayjs().format("YYYYMM")
            : route.params.year.toString() +
              route.params.month.toString().padStart(2, "0");
        await store.dispatch("my/enterAttendanceSheet", { period });
      }
      store.commit("setLoading", false);
      store.commit("clearError");
      next();
    } catch (e) {
      console.log("AttendanceSheet");
      console.log(e);
      store.commit("setError", e.message);
      store.commit("setLoading", false);
      if (e.status && e.status === 401) {
        next({ name: "login" });
      } else {
        next(false);
      }
    }
  },
};
</script>

<style>
.b-table.attendances .table.is-striped tr.is-selected {
  background-color: #f2effb;
  color: #363636;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.b-table.attendances .table.is-striped tr.is-selected td {
  border-color: transparent;
}

.content-button {
  width: 2.2rem;
}
</style>
